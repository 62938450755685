import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import RelatedRateOptions from "../components/Repeating/RelatedRateOptions";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";
  return (
    <Layout>
      <SearchEngineOptimization
        title="Graduated Payment Mortgage | Right Start | Mortgage Lender"
        description="Learn about the benefits of a graduated payment mortgage, where your home loan payment starts small and grows as your income does. Call today to request rates!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative overflow-hidden py-20 md:py-28">
        <div className="absolute left-0 top-0 h-full w-full ">
          <StaticImage
            src="../images/3.0 Mortgage Rate Options/Hero.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="container">
          <div className="relative max-w-[640px]">
            <h1 className="text-white">Graduated Payment Mortgages</h1>
            <p className="mb-0 text-white">
              If you have a low to moderate income and need a mortgage payment
              that will grow as your income does, a graduated payment loan fits
              the bill.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-20 bg-primary-50 py-20 md:mb-32 md:py-32">
        <div className="container">
          <div className="grid md:grid-cols-12 md:gap-x-14 lg:gap-x-18">
            <ScrollNavigation className="hidden md:col-span-4 md:col-start-1 md:block">
              <li>
                <AnchorLink
                  to={url + "#section-1"}
                  title="What is it?"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-2"}
                  title="How it works"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-3"}
                  title="Timeframe"
                  stripHash
                />
              </li>

              <li>
                <AnchorLink
                  to={url + "#section-4"}
                  title="Examples"
                  stripHash
                />
              </li>
            </ScrollNavigation>

            <div className="space-y-12 md:col-span-8 md:col-end-13 md:space-y-24">
              <div
                id="section-1"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h2 className="heading-four">
                  ?What is a Graduated Payment Mortgage?
                </h2>
                <p className="mb-0">
                  The monthly payments on a graduated payment mortgage start at
                  a set amount and gradually increase over time. This allows
                  prospective homeowners to start with a lower monthly mortgage
                  payment and can also be an option for those who might not
                  otherwise qualify for a loan (for example, when interest rates
                  are high, pricing them out).
                </p>
              </div>

              <div
                id="section-2"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h2 className="heading-four">
                  How Does a Graduated Payment Mortgage Work?
                </h2>
                <p className="mb-0">
                  The key to the graduated payment loan is that interest is
                  deferred, allowing prospective home buyers to pay less
                  interest in the early months or years of the loan. The
                  difference in interest is paid off later when it’s added to
                  the principal. This means you do pay more interest in the long
                  run. However, you get more affordable payments early on.
                </p>
              </div>

              <div
                id="section-3"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h2 className="heading-four">
                  How Long Does a Graduated Payment Mortgage Last?
                </h2>
                <p className="mb-0">
                  With a graduated payment mortgage, your monthly payments will
                  increase each year for up to 10 years, depending on the terms
                  of your loan. After that period, your monthly mortgage payment
                  will remain fixed.
                </p>
              </div>

              <div
                id="section-4"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h2 className="heading-four">
                  Types of Graduated Payment Mortgage Loans
                </h2>
                <ul className="styled-list-checkmark">
                  <li>FHA</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <ValueProps />
      <About />
      <RelatedRateOptions hiddenRate={4} />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
